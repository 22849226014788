// dropzone
ngx-dropzone {
  border: 2px dashed var(--theme-deafult) !important;
  height: auto !important;
  background: rgba(115, 102, 255, 0.1) !important;
  padding: 50px;

  i {
    font-size: 48px !important;
    color: var(--theme-default);
  }
}

.dropzone.dz-clickable * {
  background: transparent;
}

ngx-dropzone-label {
  z-index: 6 !important;
}

.dz-message {
  h6 {
    font-weight: 600;
    font-size: 16px;
    color: $dark;
  }

  span {
    color: $dark;
    font-size: 14px;
  }
}

.dz-message {
  h4 {
    font-weight: 200;
    margin-top: 20px;
  }
}

.node-content-wrapper.node-content-wrapper-active {
  &:hover {
    background-color: $primary-color;
    color: $white;
  }
}

.node-content-wrapper-active.node-content-wrapper-focused {
  background-color: $primary-color;
  color: $white;
}

.custom-datepicker {
  border: 1px solid $transparent-color !important;

  .form-select {
    color: #000;
  }

  .custom-day {
    font-size: 14px;
    text-align: center;
    padding: 0;
    display: inline-block;
    width: 2rem;
    height: 2rem;
    line-height: 2rem;
  }

  ngb-datepicker,
  .dropdown-menu {
    border: none;
    border-radius: 8px;
    width: 100%;
  }

  .ngb-dp-header {
    padding: 20px 0 10px 0;
  }

  .ngb-dp-months {
    display: inherit;
    width: 100%;
    background-color: $white;
  }

  .ngb-dp-weekday {
    color: $theme-body-font-color;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 11.2px;
  }

  .ngb-dp-weekdays {
    border-bottom: 1px solid $transparent-color;
  }

  .ngb-dp-day,
  .ngb-dp-week-number,
  .ngb-dp-weekday,
  .ngb-dp-arrow {
    width: 100%;
    height: 30px;
    line-height: 30px;
    font-style: inherit;

    [ngbDatepickerDayView] {
      width: 100%;
      height: 30px;
      line-height: 30px;
    }

    .btn-light {
      background-color: $white !important;
      border-color: $white !important;
      font-size: 14px;
      color: $black !important;

      &:hover,
      &:focus,
      &:active,
      &.active {
        background-color: $primary-color !important;
        color: $white !important;
      }
    }

    .btn-link {
      color: $white;

      .ngb-dp-navigation-chevron {
        width: 9px;
        height: 9px;
      }
    }
  }

  .ngb-dp-navigation-select {
    .form-select {
      width: 100px;

      &:first-child {
        margin-right: 15px;
      }
    }
  }

  .bg-light {
    background-color: $white !important;
  }

  .ngb-dp-month {
    &:first-child {
      .ngb-dp-week {
        padding-left: 15px;
      }
    }

    &:last-child {
      .ngb-dp-week {
        padding-right: 15px;
      }
    }

    .ngb-dp-week {
      &:last-child {
        padding-bottom: 15px;
      }
    }
  }
}

.swiper {
  .swiper-wrapper {
    height: 35px;
  }
}

.modal-dialog {
  .modal-content {
    .modal-body {
      .btn-close {
        position: absolute !important;
        right: 15px !important;
        top: 10px !important;
      }
    }
  }
}

.product-modal {
  .product-box {
    .product-details {
      a {
        :focus-visible {
          color: transparent;
        }
      }

      h6 {
        text-transform: capitalize;
      }

      .product-price {
        font-size: 22px;
        margin-bottom: 10px;
      }

      .product-view {
        padding: 20px 0;
        border-top: 1px dotted $semi-dark;
        border-bottom: 1px dotted $semi-dark;
      }

      .product-size {
        margin: 20px 0;

        ul {
          li {
            display: inline-block;

            +li {
              margin-left: 5px;
            }
          }
        }
      }

      .addcart-btn {
        margin-top: 30px;
      }

      .product-qnty {
        margin-bottom: 20px;

        .input-group {
          width: 35%;

          .btn {
            padding: 5px 12px;
          }

          .btn-primary {
            background-color: $semi-dark !important;
            border: 1px solid $semi-dark !important;
          }
        }
      }
    }
  }
}

.table-responsive {
  .form-select {
    color: $theme-body-font-color;
  }
}

.action {
  li {
    list-style: none;
  }
}

.accordion-button {
  padding: 0 !important;
  overflow: hidden;

  .title-padding {
    width: 100%;
    padding: 10px 20px;
    font-size: 14px !important;
  }
}

.accordion-button.collapsed {
  .title-padding {
    &.red {
      // background-color: #ffffff;
      // color: #dc3545;
      background-color: #dc3545 !important;
      color: #fff;
    }

    &.primary {
      background-color: var(--theme-light-bg);
      color: var(--theme-deafult);
    }
  }
}

.accordion-button:not(.collapsed) {
  .title-padding {
    &.red {
      // background-color: #dc3545 !important;
      background-color: #ed5463 !important;
    }

    &.primary {
      background-color: var(--theme-deafult);
    }

    color: #fff;
  }

  // color: $primary-color;
}

.cancelled-red {
  .accordion-button.collapsed::after {
    background-image: url("/assets/svg/chevron-down-white.svg") !important;
    position: absolute;
    right: 1rem;
  }
}

.accordion-button.collapsed::after {
  background-image: url("/assets/svg/chevron-down.svg");
  position: absolute;
  right: 1rem;
}

.accordion-button:not(.collapsed)::after {
  background-image: url("/assets/svg/chevron-down-white.svg");
  position: absolute;
  right: 1rem;
}

ngb-datepicker {
  ngb-datepicker-navigation-select {
    select {
      background-color: transparent !important;
    }
  }

  .ngb-dp-header {
    background-color: transparent;
    padding-top: 15px;

    .ngb-dp-arrow-btn {
      background-color: $primary-color;
      color: $white;
      padding: 0px 10px;
    }
  }

  .ngb-dp-weekday {
    color: $primary-color;
  }

  .ngb-dp-content {
    .ngb-dp-week {
      color: $dark-small-font-color;

      .btn-light {
        background-color: transparent !important;
        border-color: $dark-card-border !important;
        color: rgba(82, 82, 108, 0.8) !important;

        &:hover {
          background-color: $primary-color !important;
          border-color: $primary-color !important;
          color: $white !important;

          &.text-muted {
            color: $white !important;
          }
        }

        &.text-white {
          background-color: $primary-color !important;
          border: 1px solid $primary-color !important;
          color: $white !important;
        }
      }
    }
  }

  .ngb-dp-weekdays {
    background-color: transparent;
  }
}

.ngb-dp-month-name {
  background-color: $dark-card-background;
  color: $dark-all-font-color;
}

.digits {
  .input-group {
    .btn {
      z-index: 0 !important;
    }
  }
}

.ng-cross-none {
  .ng-select-container.ng-has-value {
    .ng-clear-wrapper {
      display: none !important;
    }
  }
}

@media only screen and (max-width: 821px) {
  .mct-table-responsive {
    overflow-x: auto !important;
  }
}

.mct-anim-checkBox {
  &:after {
    top: 0.5rem !important;
    left: -0.25rem !important;
  }

  &:before {
    top: 0.9rem !important;
    left: 0.1rem !important;
  }
}

.form-control-dropdown {
  .disabled-option {}

  .ng-select-container {
    height: 30px !important;
    font-size: 12px;

    .ng-input {
      input {
        height: auto !important;
      }
    }
  }
}

.form-control {
  font-size: 0.75rem !important;
}

.form-label,
label {
  margin-bottom: 2px !important;
  font-size: 12px;
}

.all-title {
  font-size: 14px !important;
}

.text-danger {
  font-size: 12px !important;
}

.card-body {
  .dataTables_wrapper {
    .dataTables_length {
      margin-bottom: 3px !important;

      select {
        padding: 0px 2px;
        height: 22px;
      }
    }

    .dataTables_filter {
      margin-bottom: 3px !important;

      input {
        height: 25px !important;
      }
    }

    .dataTables_info {
      font-size: 12px !important;
    }

    .dataTables_paginate {
      .paginate_button {
        padding: 5px 30px;
        line-height: 13px;
        font-size: 12px;
        border: 1px solid #e1e1e1;

        &:hover {
          border: 1px solid #000;
          color: #000 !important;
        }
      }

      span {
        display: inline-flex !important;

        .paginate_button {
          padding: 0px !important;
          width: 25px !important;
          aspect-ratio: 1/1 !important;
          display: flex !important;
          justify-content: center !important;
          align-items: center !important;
          font-size: 12px;
          border: 1px solid #e1e1e1;

          &.current {
            background-color: var(--theme-deafult);
            color: #fff !important;
          }

          &:hover {
            border: 1px solid #000;
            color: #000 !important;
          }
        }
      }
    }

    .dataTable {
      thead {
        tr {
          th {
            .checkbox_animated {
              border: 1px solid #efefef;
              height: 14px;
              margin-top: 10px;

              &::after,
              &::before {
                display: none !important;
              }
            }

            padding: 8px 12px;
          }
        }
      }

      tbody {
        tr {
          td {
            .checkbox_animated {
              border: 1px solid #efefef;
              height: 14px;
              margin-top: 10px;

              &::after,
              &::before {
                display: none !important;
              }
            }
          }
        }
      }
    }
  }
}

.dropdown-apply-btn {
  line-height: 15px;
  font-size: 12px;
  padding: 0px 20px;
}

.custom-size-btn {
  font-size: 12px;
  line-height: 16px;
}

// .modal-content .modal-body p {
//   margin: 1rem 0;
// }

.modal-content {
  .modal-title {
    font-size: 16px;
  }

  .modal-body {
    p {
      margin: 1rem 0;
    }
  }
}

.btn {
  font-size: 12px;
  line-height: 16px;
  //    padding-right: 1.5rem !important;
  // padding-left: 1.5rem !important;
  // padding-top: 0.25rem !important;
  // padding-bottom: 0.25rem !important;
  padding: 0.25rem 1.5rem !important;
}

.btn-close {
  padding: 0 !important;
  margin: 0 !important;
}

// ------------------------------- Language Btn ----------------------------------------------

.language-btn {
  font-size: 12px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 4px 12px;
  cursor: pointer;

  &.active {
    color: #fff;
    background-color: var(--theme-deafult);
  }

  &:not(.active):hover {
    background-color: var(--theme-light-bg);
  }

  &:not(:first-child) {
    border-left: 1px solid #c1c1c1;
  }
}

.custom-view {
  .owl-carousel.owl-drag .owl-item {
    max-height: 70vh;
    overflow-y: auto;

    &::-webkit-scrollbar {
      display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    & {
      -ms-overflow-style: none;
      /* IE and Edge */
      scrollbar-width: none;
      /* Firefox */
    }
  }
}

/* Define CSS classes for select box */
.custom-mct-select {
  /* Add your custom styles here */
  border-radius: 4px;
  padding: 5px 10px;
  height: 30px !important;
  font-size: 12px;
  color: #333;
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #ccc;
}

/* Style for dropdown arrow */
.custom-mct-select::after {
  content: '\25BC';
  /* Unicode character for downward arrow */
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
}

.mct-custom-dataTable-design {
  tr {
    td {
      padding-top: 5px !important;
      padding-bottom: 5px !important;
      // text-align: center;
      vertical-align: middle;

      .status {
        width: 100%;
        text-align: center;
      }

      .action-btn-box {
        border-radius: 10rem;
        overflow: hidden;
        border: 1px solid #dfe6ff;

        .edit-btn {
          background-color: #eae8ff;
          color: #5f50ff;
          display: flex;
          align-items: center;
          justify-content: center;
          border: 1px solid #eae8ff;

          &:hover {
            background-color: var(--theme-deafult);
            color: #ffffff;
            border: 1px solid var(--theme-deafult);
          }
        }

        .view-btn {
          background-color: #ffffffcc;
          color: var(--theme-deafult);
          display: flex;
          align-items: center;
          justify-content: center;
          border: 1px solid #eae8ff;

          &:hover {
            background-color: var(--theme-deafult);
            color: #ffffff;
            border: 1px solid var(--theme-deafult);
          }
        }

        .copy-btn {
          background-color: #eae8ff;
          color: #5f50ff;
          display: flex;
          align-items: center;
          justify-content: center;
          border: 1px solid #eae8ff;

          &:hover {
            background-color: var(--theme-deafult);
            color: #ffffff;
            border: 1px solid var(--theme-deafult);
          }
        }
      }
    }
  }
}

.members-img{
  width: 2.5rem;
  aspect-ratio: 1/1;
}