/* You can add global styles to this file, and also import other style files */
@import "./assets/scss/app.scss";

:root {

  /* Hide scrollbar for IE, Edge and Firefox */
  & {
    -ms-overflow-style: none; 
    /* IE and Edge */
    // scrollbar-width: none;
    /* Firefox */ 
  } 
} 

.router-wrapper {
  position: relative;
  overflow: hidden;
  width: 100vw;
  height: calc(100% - 47px);
  perspective: 1200px;
  transform-style: preserve-3d;
}

:host {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  background: #60d7a9;
}

.form-control::placeholder {
  color: #acacac;
}

.textarea {
  .angular-editor-textarea {
    min-height: 600px !important;
  }
}